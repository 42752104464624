@import "colors";

body {
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  min-width: 100vw;
  background: black url("assets/bg.webp") no-repeat;
  background-size: cover;
  background-position: top;
  overflow-x: hidden;
  color: $fnt-primary;
}

h1, h2, h3, h4, h5 {
  color: $fnt-primary !important;
}

a:any-link {
  color: $link;
}

#root {
  width: 100%;
  height: 100%;
}
