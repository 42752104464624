@import "colors";

.section {
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  margin: 9px;

  h3 {
    font-size: 2.3rem;
    font-weight: bold;
    text-shadow: 1px 1px 5px $title-shadow;
  }
}
