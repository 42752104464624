@import "colors";

#home {
  div {
    display: flex;
    flex-flow: column;
    width: fit-content;
    margin: auto;

    h1 {
      width: fit-content;
      margin: 0;
      margin-top: 60px;
      font-size: 15rem;
      font-family: 'Comfortaa', cursive;
      text-shadow: darken($title-first, 8) 5px 5px;
      color: $title-first;
    }

    h2 {
      font-family: 'Comfortaa', cursive;
      font-weight: normal;
      width: fit-content;
      float: right;
      color: $fnt-primary;
      margin: 0;
      margin-left: auto;
      font-size: 1.8rem;
    }
  }
}
