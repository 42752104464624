@import "colors";

#app {
  height: 100vh;
  display: flex;
  flex-flow: row;

  .content {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;

    @media (max-width: 1024px) {
      padding: 13px;
    }
  }

  #nav {
    $line-height: 1.65rem;
    display: flex;
    flex-flow: column;
    margin-left: 25px;
    justify-content: center;
    height: 100vh;

    @media (max-width: 1024px) {
      display: none;
    }

    > div {
      $margin: 5px;
      width: fit-content;
      display: flex;
      flex-flow: column;
      position: relative;
      transition-duration: .5s;
      top: calc(var(--index) * calc(#{$line-height} + #{2 * $margin}));

      a {
        transition-duration: .5s;
        transition-property: font-weight, font-size;
        float: left;
        line-height: $line-height;
        width: 350px;

        &:any-link {
          font-size: 1.5rem;
          text-decoration: none;
          color: $fnt-link;
          margin: $margin 0;
        }

        &.active {
          float: none;
          font-weight: bold;
          font-size: $line-height;
        }
      }
    }
  }
}
