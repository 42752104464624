@import "colors";

#footer {
  width: 100%;
  color: $fnt-primary;
  padding: 20px 0;

  p, a {
    width: fit-content;
    margin: auto;
    text-align: center;
  }

  a:any-link {
    text-decoration: none;
    color: darken($fnt-primary, .35);
  }
}
