#contacts {
  th {
    float: right;
  }

  table {
    border-collapse: separate;
    border-spacing: .5rem;
  }
}
